@import 'App';

.theory-text {
  width: 50%;

  @include respond-to('phone') {
    width: 100%;
    height: 50%;
  }

  @include respond-to('tablet-portrait') {
    width: 75%;
  }
}

.theory-button {
  display: inline-block;
  border: 1px solid;
  border-radius: 10px;
  font-weight: 400;
  margin-top: 1em;
  padding: 1em 1.5em;
  @include ms-respond(font-size,0);
}