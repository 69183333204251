@import 'common';

.about-text {
  width: 50%;
  align-self: center;

  @include respond-to('phone') {
    width: 100%;
    text-align: left;
  }

  @include respond-to('tablet-portrait') {
    width: 70%;
  }
}

.about-subtitle {
  width: 50%;
  text-align: left;
  
  @include respond-to('phone', 'tablet-portrait') {
    width: auto;
  }
}

.about-image {
  position: absolute;
  height: 100%;
  right: 0;
  width: auto;

  @include respond-to('phone', 'tablet-portrait') {
    display: none;
  }
}

.scroll-indicator-wrapper {
  display: inline-block;
}