@import 'common';

.arrow {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  animation: fadein 3s, 
    pulse 2s infinite;

  @include respond-to('phone') {
    width: 8vw;
    height: 8vw;
  }

  @include respond-to('tablet-portrait') {
    width: 4vw;
    height: 4vw;
  }
}

.indicatorContainer {
  text-align: center;
}

.scroll {
  font-weight: 300;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes pulse {
  0% {
    transform: scale(1.00);
  }

  30% {
    transform: scale(0.98);
  }

  60% {
    transform: scale(0.96);
  }
  
  100% {
    transform: scale(0.94);
  }
}