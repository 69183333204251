@import 'common';

$transition-long-duration: 1000ms;
$transition-short-duration: 100ms;

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@mixin theme($background-color, $text-color) {
  transition: all $transition-long-duration;
  background: $background-color;
  color: $text-color;

  svg {
    overflow: visible;
    fill: $text-color;
  }

  .navbarItem-a {
    color: $text-color;
  }

  .sectionNumber-index {
    border-color: $text-color;
  }
}

.grad-theme {
  @include theme($gradient, $primary-color);
}

.light-theme {
  @include theme($secondary-color, $primary-color);
}

.light-blue-theme {
  @include theme($primary-color-light, $primary-color);
}

.dark-theme {
  @include theme($primary-color, $secondary-color);
}


.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity $transition-long-duration;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity $transition-long-duration;
}


.sidebar {
  position: absolute;
  height: 100%;
  left: 2.5%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 10;

  @include respond-to('phone', 'tablet-portrait') {
    display: none;
  }
}

.title {
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  width: 7%;
  height: 7%;

  @include respond-to('phone') {
    width: 20%;
    height: 20%;
    top: 1%;
  }

  @include respond-to('tablet-portrait') {
    width: 10%;
    height: 10%;
  }
}

.sectionNumber {
  position: absolute;
  bottom: 4%;
  right: 3%;

  @include respond-to('phone') {
    top: auto;
    bottom: 2.5%;
    right: 2.5%;
  }
}

.title > p {
  margin: 0;
  display: inline-block;
  text-align: left;
}

.content {
  height: 100%;
  width: 100%;

  @include respond-to('phone') {
    margin-top: 1em;
  }

  @include respond-to('phone', 'tablet-portrait') {
    padding-left: 1em 1em 0 1em;
  }
}