@import 'common';

.functionality-text {
  width: 30%;

  @include respond-to('phone') {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  @include respond-to('tablet-portrait') {
    width: 50%;
  }
}

.new-label {
  font-weight: bold;
  color: #FF5831;
}

.new-label-hidden {
  opacity: 0;
}

.device {
  height: 60%;
  width: auto;
  margin-right: 5em;

  @include respond-to('phone') {
    margin-right: 0;
    height: 50%;
  }

  @include respond-to('tablet-portrait') {
    margin-right: 2rem;
    height: 50%;
  }
}