@import url('https://fonts.googleapis.com/css?family=Karla:600|Nunito+Sans:300,400,700,800,900&display=swap');
@import '~modularscale-sass/stylesheets/modularscale';

$breakpoints: (
  'phone': '(max-width: 599px)',
  'tablet-portrait': '(min-width: 600px) and (max-width: 899px)',
  'tablet-landscape': '(min-width: 900px) and (max-width: 1199px)',
  'desktop': '(min-width: 1200px) and (max-width: 1799px)',
  'big-desktop': '(min-width: 1800px)'  
) !default;

@mixin respond-to($breaks...){
  @each $breakpoint in $breaks {
    @if map-has-key($breakpoints, $breakpoint) {
      @media #{map-get($breakpoints, $breakpoint)} {
        @content;
      }
    }
  }
}

$primary-color: #00294F;
$gradient: linear-gradient(90deg, rgba(240,250,255,1) 0%, rgba(174,184,219,0.8309698879551821) 100%);
$secondary-color: white;
$primary-color-light: #DFE9FE;

$modularscale: (
  ratio: 1.333,

  0px: (
    base: 0.7em,
  ),

  350px: (
    base: 0.9em
  ),

  900px: (
    base: 1.1em
  ),

  1200px: (
    base: 1.2em,
  )
);

body, h1, h2, h3, h4, h5, p, a, button {
  font-family: 'Nunito Sans', sans-serif;
}

h1, h2, h3, h4 {
  margin-top: 0;
}

h1 {
  font-weight: 900;
  @include ms-respond(font-size, 4);
  @include ms-respond(line-height, 0);
  margin-bottom: 0.5em;

  @include respond-to('phone') {
    font-size: 12vw;
  }
}

h2 {
  font-weight: 900;
  @include ms-respond(font-size, 3);
  @include ms-respond(line-height, 0);
  font-weight: bold;
  margin-bottom: 1rem;
}

h3 {
  font-weight: 900;
  @include ms-respond(font-size, 2);
  @include ms-respond(line-height, 0);
}

h4 {
  font-weight: 300;
  @include ms-respond(font-size, 1);
  @include ms-respond(line-height, 1);
}

p {
  font-weight: 300;
  @include ms-respond(font-size, 0);
  @include ms-respond(line-height, 1);
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

.content-layout {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  @include respond-to('phone') {
    padding: 0.5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}