@import 'common';

.sectionNumber {
  display: flex;
  @include ms-respond(font-size, 0);
}

.sectionNumber-index {
  font-weight: bold;
  text-align: center;
}

.sectionNumber-total {
  font-weight: 300;
  text-align: center;
}