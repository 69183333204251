@import 'common';

.contact-header {
  width: 30%;
  margin-left: 10%;

  @include respond-to('phone') {
    width: 100%;
    margin-left: 0%;
    height: auto;
  }

  @include respond-to('tablet-portrait') {
    width: 50%;
    margin-left: 0%;
  }
}

.contact-title {
  width: 70%;
    
  @include respond-to('phone') {
    width: 100%;
  }
}

.contact-information {
  margin-left: 10%;
  width: 25%;

  @include respond-to('phone') {
    margin-left: 0;
    width: 100%;
    height: auto;
  }

  @include respond-to('tablet-portrait') {
    margin-left: 4%;
    width: auto;
  }
}

.contact-information h4 {
  font-weight: bold;
}

.contact-logo {
  width: 10vw;
  height: auto;

  @include respond-to('phone') {
    width: 35vw;
    height: auto;
  }
}

.contact-logo-wrapper {
  margin-top: 6em;

  @include respond-to('phone'){
    margin-top: 1em;
  }

  @include respond-to('tablet-portrait') {
    margin-top: 3em;
  }
}