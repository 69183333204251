@import 'common';

.navbar-ul {
  padding: 0;

  @include respond-to('phone', 'tablet-portrait') {
    display: none;
  }
}

.navbarItem-li {
  list-style: none;
  padding-bottom: 1.3em;
}

.navbarItem-a {
  font-size: 1.125rem;
  font-weight: 300;
  text-decoration: none;
    
  @include respond-to('phone', 'tablet-portrait') {
    display: inline;
  }
}

.navbarItem-a-active {
  font-weight: 900;
}